/* cyrillic-ext */
@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-weight: 700;
  src: local('Futura Bold'), local('Futura-Bol'),
    url(https://fonts.gstatic.com/l/font?kit=-W_8XJn5VyXFzgiC2_8IZGMuUZctdg&skey=d46fe7911eaea679&v=v9) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-weight: 700;
  src: local('Futura Bold'), local('Futura-Bol'),
    url(https://fonts.gstatic.com/l/font?kit=-W_8XJn5VyXFzgiC2_8IbWMuUZctdg&skey=d46fe7911eaea679&v=v9) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-weight: 700;
  src: local('Futura Bold'), local('Futura-Bol'),
    url(https://fonts.gstatic.com/l/font?kit=-W_8XJn5VyXFzgiC2_8IZ2MuUZctdg&skey=d46fe7911eaea679&v=v9) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-weight: 700;
  src: local('Futura Bold'), local('Futura-Bol'),
    url(https://fonts.gstatic.com/l/font?kit=-W_8XJn5VyXFzgiC2_8IaWMuUZctdg&skey=d46fe7911eaea679&v=v9) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: 'Prophet-Medium';
  src: url('./assets/fonts/prophet-medium.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/prophet-medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/prophet-medium.otf') format('opentype'),
    /* Open Type Font */ url('./assets/fonts/prophet-medium.svg') format('svg'),
    /* Legacy iOS */ url('./assets/fonts/prophet-medium.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/prophet-medium.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/prophet-medium.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Serif Gothic W01 Black';
  src: url('./assets/fonts/7c08dbc9894b330a20c2890ad32b0944.eot'); /* IE9*/
  src: url('./assets/fonts/7c08dbc9894b330a20c2890ad32b0944.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/7c08dbc9894b330a20c2890ad32b0944.woff2') format('woff2'),
    /* chrome、firefox */ url('./assets/fonts/7c08dbc9894b330a20c2890ad32b0944.woff') format('woff'),
    /* chrome、firefox */ url('./assets/fonts/7c08dbc9894b330a20c2890ad32b0944.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('./assets/fonts/7c08dbc9894b330a20c2890ad32b0944.svg#ITC%20Serif%20Gothic%20W01%20Black') format('svg'); /* iOS 4.1- */
}

@font-face {
  font-family: 'Ambiant-Book';
  src: url('./assets/fonts/Ambiant-Book.woff') format('woff'), url('./assets/fonts/Ambiant-Book.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PPMonumentExtended';
  src: url('./assets/fonts/PPMonumentExtended-Regular.woff') format('woff'),
    url('./assets/fonts/PPMonumentExtended-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PPMonumentExtended';
  src: url('./assets/fonts/PPMonumentExtended-Black.woff') format('woff'),
    url('./assets/fonts/PPMonumentExtended-Black.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
